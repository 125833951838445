<template>
    <div  class="wrap">
            <div :id="imgId" class="imgGrop">
                <div class="left middleCenter" v-for="(item,index) in fileLists" :id="index+1" :key="index+1" :data-id="item.url">
                    <img class="img" :src="item.url" >
                    <div class="content-wrap">
                        <div class="content middleCenter">
                            <i class="el-icon-zoom-in ci" @click="handlePictureCardPreview(item)" ></i>
                            <i class="el-icon-delete ci" @click="handleRemove(item)"></i>
                        </div>
                    </div>
                </div>
            </div>
            <el-upload
                    class="wrap"
                    :disabled="isDisabled"
                    :action="actionUrl"
                    list-type="picture-card"
                    accept=".jpg,.jpeg,.png"
                    :file-list="fileLists"
                    :http-request="upload"
                    :auto-upload="false"
                    :show-file-list="false"
                    :on-change="handleChange"
                    :multiple="true"
                    :class="{hide:hideUploadEdit}">
                <i slot="default" class="el-icon-plus" ></i>
            </el-upload>
        <el-dialog :visible.sync="dialogVisible" append-to-body>
            <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>

        <!-- 剪裁组件弹窗 -->
        <el-dialog
                :visible.sync="cropperModel"
                width="800px"
                :before-close="beforeClose"
                :modal="false"
        >
            <CropperImage
                    :img-file="file"
                    ref="vueCropper"
                    :fixedNumber="fixedNumber"
                    :autoCropWidth="width"
                    :autoCropHeight="height"
                    @upload="upload"
            >
            </CropperImage>
        </el-dialog>
    </div>
</template>

<script>
    import ty from "../../utils/ty";
    import CropperImage from "./CropperImage";
    import axios from "axios";
    import Sortable from 'sortablejs';
    export default {
        name: "VploadImg",
        components:{
            CropperImage
        },
        props:{
            width: {
                // 单图剪裁框宽度
                type: Number,
                default: 178,
            },

            height: {
                // 单图剪裁框高度
                type: Number,
                default: 178,
            },

            fixedNumber: {
                // 剪裁框比例设置
                default: function () {
                    return [1, 1.3];
                },
            },
            limit:{
                type:Number,
                default:12,
            },
            action:{
                type:String,
                default:'/common/uploadFileUrl'
            },
            type:{
                type:Number,
                default:0
            },
            isDisabled:{
              type:Boolean,
              default:false
            },
            fileList:{
                type:Array,
                default: ()=>{
                    return []
                }
            },
            isSort:{
                type:Boolean,
                default:false
            },
            imgId:{
              type:String,
              default:"imgGrop"
            }
        },
        data(){
            return{
                dialogImageUrl: '',
                dialogVisible: false,
                cropperModel: false, // 剪裁组件弹窗开关
                actionUrl: this.action+'?fileType=0&type='+this.type,
                hideUploadEdit:this.fileList.length>=this.limit,
                fileLists:this.fileList,
                editForm:{
                    url:'',
                    uid:null
                },
                editView:false,
                file: '', // 当前被选择的图片文件
            }
        },
        updated () {
            if (this.$refs.vueCropper) {
                this.$refs.vueCropper.Update()
            }
        },
        watch:{
            fileList(n,o){ //n为新值,o为旧值;
                this.fileLists = n;
                this.value=n
                this.hideUploadEdit=this.fileList.length>=this.limit
            }
        },
        mounted () {
            var el = document.getElementById(this.imgId);
            const that=this;
            var sortable = Sortable.create(el,{
                sort:that.isSort,
                // 结束拖拽
                onEnd: function (obj) {
                    var imgList=sortable.toArray()
                    that.submitFile(imgList,2)
                },
            });
        },
        methods:{
            beforeClose (done) {
                this.fileLists.pop()
                this.cropperModel = false
            },
            // 移除图片
            handleRemove(file) {
                this.$confirm('此操作将永久删除该图片, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    for(let i in this.fileLists){
                        if(this.fileLists[i].uid == file.uid){
                            this.fileLists.splice(i,1)
                        }
                    }
                    this.hideUploadEdit = this.fileLists.length>=1;
                    this.submitFile(file.url,0)
                }).catch(() => {
                });
            },
            handlePictureCardPreview(file) {
                this.dialogImageUrl = file.url;
                this.dialogVisible = true;
            },

            //上传图片
            upload(data) {
                console.log("dTE:",data)
                let formData = new FormData();
                formData.append("file", data, "DX.png");

                console.log(data);
                this.$axios.post(this.actionUrl, formData).then((res) => {
                    let obj = {
                        status: "success",
                        url: res.msg
                    }
                    this.cropperModel = false
                    this.fileLists.push(obj)
                    this.submitFile(res.msg,1)
                });
            },

            handleChange(file,fileList){
                    // 点击弹出剪裁框
                this.cropperModel = true
                this.file = file
                //this.fixedNumber=[file.width,file.height]
                // this.imageUrl = file.url
                this.hideUploadEdit = fileList.length>=1;
            },
            handleSuccess(response,file,fileList){
                let obj = {
                    name: file.name,
                    status: "success",
                    uid: file.uid,
                    url: response.msg
                }
                this.fileLists.push(obj)
                this.submitFile(response.msg,1)
            },
            // 将图片文件传回给父组件
            submitFile(filePath,add){
                //add:0是删除。1是新增,2是重新排序
                this.$emit('submitImg',filePath,add)
            },
        }
    }
</script>

<style scoped>
    .el-icon-plus{
        font-size: 30px!important;
    }
    .el-input>>> .el-textarea__inner{
        font-size:18px!important;
    }
    .hide  >>> .el-upload--picture-card{
        display: none;
    }
    .left{
        float: left;
        width: 148px;
        height: 148px;
        border-radius: 6px;
        border: 1px solid #c0ccda;
        margin:0 20px 20px 0;
        overflow: hidden;
        position: relative;
        cursor: pointer;
    }
    .img{
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
    }
    .content-wrap{
        display:none;
        position: absolute;
        z-index: 99999999;top: 0;
        width: 100%;
        height: 100%;
        background:rgba( 0,0,0,0.4); padding-top: 70px;
    }
    .left:hover .content-wrap{display: block;}
    .content{
        width: 100%;
        height: 100%; text-align: center;
    }
    .ci {
        color: #fff;
        font-size: 18px;
    }
    .ci:nth-of-type(2){
         margin-left: 10px;
     }
    .ci:nth-of-type(1){
        margin-right: 10px;
    }
    .wrap{
        float: left;
    }
    .imgGrop{
        float: left;
    }


</style>
